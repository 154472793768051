import { isDef } from "@/utils/isUtil";

export const DecimalSeparator = {
  COMMA: ",",
  DOT: ".",
};

export const PriceStrCurrencySymbolEnum = {
  MYR: "RM ",
  VND: " ₫",
  THB: "฿ ",
  IDR: "Rp ",
  TRY: " TL",
  PHP: "₱ ",
  USD: "$ ",
  default: "",
};

export const CountryCurrencySymbolEnum = {
  MY: "MYR",
  VN: "VND",
  TH: "THB",
  ID: "IDR",
  TR: "TRY",
  PH: "PHP",
};

export const PriceStrDecimalPointEnum = {
  MYR: DecimalSeparator.DOT,
  VND: DecimalSeparator.COMMA,
  THB: DecimalSeparator.DOT,
  IDR: DecimalSeparator.COMMA,
  TRY: DecimalSeparator.COMMA,
  PHP: DecimalSeparator.DOT,
  default: DecimalSeparator.DOT,
};

export const PriceDecimalPlacesEnum = {
  MYR: 2,
  VND: 0,
  THB: 2,
  IDR: 0,
  TRY: 2,
  PHP: 2,
  default: 2,
};

// 百分号在前的国家
export const frontPercentCurrency = ["TRY"];

export function getCurrencySettingByCurrency(currency = "") {
  return {
    currency,
    decimal_places: isDef(PriceDecimalPlacesEnum[currency])
      ? PriceDecimalPlacesEnum[currency]
      : PriceDecimalPlacesEnum.default,
    decimal_separator: isDef(PriceStrDecimalPointEnum[currency])
      ? PriceStrDecimalPointEnum[currency]
      : PriceStrDecimalPointEnum.default,
  };
}
