import { createRouter, createWebHashHistory } from "vue-router";

// import MainLayout from "@/layouts/MainLayout.vue";
import HomeLayout from "@/layouts/HomeLayout.vue";

import LoginView from "@/views/LoginView.vue";
import HomeView from "@/views/home/HomeView.vue";
import MenuView from "@/views/home/MenuView.vue";
import OrdersView from "@/views/home/OrdersView.vue";
import RewardsVIew from "@/views/home/RewardsVIew.vue";
import AccountView from "@/views/home/AccountView.vue";

import MyCouponListView from "@/views/coupon/MyCouponList.vue";

import AddressLayout from "@/layouts/AddressLayout.vue";
import AddressList from "@/views/address/AddressList.vue";
import AddressEdit from "@/views/address/AddressEdit.vue";
import StoreList from "@/views/store/StoreList.vue";
import ConfirmOrder from "@/views/order/ConfirmOrder.vue";
import OrderDetail from "@/views/order/OrderDetail.vue";

import NewGift from "@/views/activity/NewComer.vue";
import SharingSharer from "@/views/activity/SharingSharer.vue";
import SharingAssistor from "@/views/activity/SharingAssistor.vue";
import ActivityHistory from "@/views/activity/ActivityHistory.vue";
import SendCoupon from "@/views/activity/SendCoupon.vue";
import RandomCoupon from "@/views/activity/RandomCoupon.vue";

import MemberQrCode from "@/views/account/MemberQr.vue";
import MyBalance from "@/views/account/MyBalance.vue";
import Recharge from "@/views/account/Recharge.vue";
// import PointHistoryView from "@/views/points/PointHistoryView.vue";

import { isLogin } from "@/utils/authUtil";

import { useShopStoreWithOut } from "@/store/shopStore";
import { useOrderListStoreWithOut } from "@/store/orderListStore";
import { useUserStoreWithOut } from "@/store/userStore";

function appendSupplierName(title) {
  if (window.__CONFIG__) {
    return `${window.__CONFIG__.supplier_name} | ${title}`;
  }
  return title;
}

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      redirect: window.__FIRST_ROUTER_PATH__
        ? window.__FIRST_ROUTER_PATH__
        : "/home",
    },
    {
      path: "/login",
      component: LoginView,
      name: "Login",
      meta: {
        keepAlive: true,
        title: `Login`,
      },
    },
    {
      path: "/home",
      redirect: "/home/index",
      component: HomeLayout,
      children: [
        {
          path: "index",
          name: "Index",
          component: HomeView,
          meta: {
            keepAlive: true,
            disableAnimation: true,
            title: appendSupplierName("Home"),
          },
        },
        {
          path: "menu",
          name: "Menu",
          component: MenuView,
          meta: {
            keepAlive: true,
            disableAnimation: true,
            title: appendSupplierName("Menu"),
          },
        },
        {
          path: "order",
          name: "Orders",
          component: OrdersView,
          meta: {
            keepAlive: true,
            disableAnimation: true,
            title: appendSupplierName("Orders"),
          },
        },
        {
          path: "reward",
          name: "Rewards",
          component: RewardsVIew,
          meta: {
            keepAlive: true,
            disableAnimation: true,
            title: appendSupplierName("Rewards"),
          },
        },
        {
          path: "account",
          name: "Account",
          component: AccountView,
          meta: {
            keepAlive: true,
            disableAnimation: true,
            title: appendSupplierName("Account"),
          },
        },
      ],
    },
    {
      path: "/address",
      redirect: "/address/",
      component: AddressLayout,
      children: [
        {
          path: "list",
          name: "AddressList",
          component: AddressList,
          meta: {
            keepAlive: false,
            disableAnimation: false,
            title: "Address List",
          },
        },
        {
          path: "edit",
          name: "AddressEdit",
          component: AddressEdit,
          meta: {
            keepAlive: false,
            disableAnimation: false,
            title: "Address Edit",
          },
        },
      ],
    },
    {
      path: "/confirm_order",
      name: "ConfirmOrder",
      component: ConfirmOrder,
      meta: {
        title: "Order Confirmation",
      },
    },
    {
      path: "/order_detail",
      name: "OrderDetail",
      component: OrderDetail,
      meta: {
        // keepAlive: true,
        disableAnimation: false,
        title: "Order Details",
      },
    },
    {
      path: "/store_list",
      name: "StoreList",
      component: StoreList,
      meta: {
        keepAlive: false,
        disableAnimation: false,
        title: "Store List",
      },
    },
    // {
    //   path: "/points_history",
    //   name: "PointsHistory",
    //   component: PointHistoryView,
    //   meta: {
    //     title: "Points History",
    //   },
    // },
    {
      path: "/my_coupon",
      name: "MyCouponList",
      component: MyCouponListView,
      meta: {
        keepAlive: false,
        disableAnimation: false,
        title: "Vouchers",
      },
    },
    {
      path: "/activity",
      name: "Activity",
      children: [
        {
          path: "new_gift",
          name: "NewGift",
          component: NewGift,
          meta: {
            keepAlive: false,
            disableAnimation: false,
            title: "New Gift",
          },
        },
        {
          path: "sharing_sharer",
          name: "SharingSharer",
          component: SharingSharer,
          meta: {
            keepAlive: false,
            disableAnimation: false,
          },
        },
        {
          path: "sharing_assistor",
          name: "SharingAssistor",
          component: SharingAssistor,
          meta: {
            keepAlive: false,
            disableAnimation: false,
          },
        },
        {
          path: "send_coupon",
          name: "SendCoupon",
          component: SendCoupon,
          meta: {
            keepAlive: false,
            disableAnimation: false,
          },
        },
        {
          path: "random_coupon",
          name: "RandomCoupon",
          component: RandomCoupon,
          meta: {
            keepAlive: false,
            disableAnimation: false,
          },
        },
        {
          path: "history",
          name: "ActivityHistory",
          component: ActivityHistory,
          meta: {
            keepAlive: false,
            disableAnimation: false,
          },
        },
      ],
    },
    {
      path: "/member_qr",
      name: "MemberQrCode",
      component: MemberQrCode,
      meta: {
        keepAlive: false,
        disableAnimation: false,
        title: "MemberQr",
      },
    },
    {
      path: "/my_balance",
      name: "MyBalance",
      component: MyBalance,
      meta: {
        keepAlive: false,
        disableAnimation: false,
        title: "Recharge",
      },
    },
    {
      path: "/recharge",
      name: "Recharge",
      component: Recharge,
      meta: {
        keepAlive: false,
        disableAnimation: false,
        title: "Reload",
      },
    },
  ],
});

const whiteList = [
  "Login",
  "NewGift",
  "SharingSharer",
  "SharingAssistor",
  "SendCoupon",
  "RandomCoupon",
]; // no redirect whitelist
const getLocationWhiteList = [
  "Login",
  "NewGift",
  "SharingSharer",
  "SharingAssistor",
  "SendCoupon",
  "RandomCoupon",
]; // 不需要获取定位
router.beforeEach(async (to, from, next) => {
  if (!isLogin() && !whiteList.includes(to.name)) {
    next({
      name: "Login",
    });
    return;
  }
  // 在订单确认页刷新时，返回Menu页
  if (to.name === "ConfirmOrder" && !from.name) {
    next({
      name: "Menu",
    });
    return;
  }

  if (isLogin() && to.name === "Login") {
    next({
      name: "Index",
    });
    return;
  }

  const userStore = useUserStoreWithOut();
  if (!userStore.getCoordinate && !getLocationWhiteList.includes(to.name)) {
    await userStore.initCoordinate();
    next();
  } else {
    next();
  }
});
router.afterEach((to, from) => {
  const shopStore = useShopStoreWithOut();
  if (["Menu", "ConfirmOrder"].includes(to.name) && to.name !== from.name) {
    shopStore.loadShopOrderStatics();
  }

  const orderListStore = useOrderListStoreWithOut();
  if (
    to.name === "Orders" &&
    from.name !== "OrderDetail" &&
    to.name !== from.name
  ) {
    orderListStore.resetState();
  }
});

export default router;
