import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: ""
};
var _hoisted_2 = {
  class: "item-list"
};
var _hoisted_3 = {
  class: "info"
};
var _hoisted_4 = {
  class: "main"
};
var _hoisted_5 = {
  class: "text"
};
var _hoisted_6 = {
  class: "attr"
};
var _hoisted_7 = {
  class: "cart-item-statics"
};
var _hoisted_8 = {
  class: "cart-item-price"
};
import { ref } from "vue";
import Image from "@/components/base/Image.vue";
import { calcDishTagList } from "@/composable/useDishes";
import useCurrency from "@/composable/useCurrency";
import NP from "number-precision";
import SvgIcon from "@/components/base/SvgIcon.vue";
import Counter from "@/components/base/Counter.vue";
export default {
  __name: 'CartItems',
  props: {
    cartList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  emits: ["update:cart-count"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var emits = __emit;
    var _useCurrency = useCurrency(),
      useFormatPrice = _useCurrency.useFormatPrice;
    var isCartListShowMore = ref(false);
    var handleShowMore = function handleShowMore() {
      isCartListShowMore.value = true;
    };
    function handleUpdateCount(cartIndex, num) {
      // 自动展开
      handleShowMore();
      emits("update:cart-count", cartIndex, num);
    }
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.cartList, function (item, index) {
        var _item$imgs;
        return _openBlock(), _createElementBlock("div", {
          key: item.UUKey,
          class: _normalizeClass(["item", {
            'item-hidden': index > 1 && !isCartListShowMore.value
          }]),
          "oss-stylename": "type_3"
        }, [_createVNode(Image, {
          class: "img",
          src: (_item$imgs = item.imgs) === null || _item$imgs === void 0 ? void 0 : _item$imgs[0],
          alt: ""
        }, null, 8, ["src"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(item.name), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(calcDishTagList)(item).map(function (item) {
          return item.value;
        }).filter(Boolean).join(", ")), 1)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(_unref(useFormatPrice)(_unref(NP).times(item.selectedPrice, item.num), item.currency)), 1), _createVNode(Counter, {
          class: "cart-item-counter",
          count: item.num,
          size: "medium",
          "allow-input": true,
          "confirm-delete-options": {
            title: 'Delete Product',
            desc: 'Are you sure to delete this product?'
          },
          "onUpdate:count": function onUpdateCount($event) {
            return handleUpdateCount(index, $event);
          }
        }, null, 8, ["count", "onUpdate:count"])])])], 2);
      }), 128))]), !isCartListShowMore.value && __props.cartList.length > 2 ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "more-wrap",
        onClick: handleShowMore
      }, [_cache[0] || (_cache[0] = _createElementVNode("div", null, "More", -1)), _createVNode(SvgIcon, {
        name: "drop_down",
        class: "icon-drop-down"
      })])) : _createCommentVNode("", true)]);
    };
  }
};