import { defineStore } from "pinia";
import store from "./index";
import { cloneDeep } from "lodash-es";
import NP from "number-precision";
import { Persistent } from "@/utils/cache/persistent";
import { useShopStoreWithOut } from "./shopStore";
import { isArray } from "@/utils/isUtil";

const CART_KEY = "__CART__";

export const useCartStore = defineStore({
  id: "cart",
  state: () => {
    return {
      cart: [],
      coupon: null,
    };
  },
  getters: {
    getCart(state) {
      return state.cart;
    },
    getCartLength(state) {
      return state.cart.length;
    },
    getCartTotalDishNum(state) {
      return state.cart.reduce((pre, dish) => {
        return (pre += dish.num);
      }, 0);
    },
    getCartTotalPrice(state) {
      return state.cart.reduce((pre, dish) => {
        return (pre += NP.times(dish.selectedPrice, dish.num));
      }, 0);
    },
    getCartItemCurrency(state) {
      return state.cart[0]?.currency;
    },
    getCoupon(state) {
      return state.coupon;
    },
  },
  actions: {
    setCart(cart) {
      if (isArray(cart)) {
        this.cart = cart;
      }
    },
    addItem(dishItem) {
      const sameShoppingCartItemIndex = this.cart.findIndex(
        (item) => item.UUKey === dishItem.UUKey
      );
      if (sameShoppingCartItemIndex === -1) {
        // 加购物车
        this.cart.push(cloneDeep(dishItem));
      } else {
        // 改变购物车已有item的数目
        const cartItem = this.cart.find(
          (item) => item.UUKey === dishItem.UUKey
        );
        this.changeNum(sameShoppingCartItemIndex, cartItem.num + dishItem.num);
      }
    },
    changeNum(index, num) {
      if (num <= 0) {
        this.deleteItemByIndex(index);
      } else if (num <= 99) {
        this.cart[index].num = num;
      }
    },
    deleteItemByIndex(delIndex) {
      this.cart.splice(delIndex, 1);
    },
    clear() {
      this.cart.splice(0, this.cart.length);
    },
    setCoupon(coupon) {
      this.coupon = coupon;
    },
    setCartStorage() {
      const shopStore = useShopStoreWithOut();
      Persistent.setSession(CART_KEY, {
        shop_id: shopStore.getShopId,
        cart: this.cart,
      });
    },
    getCartStorage() {
      return Persistent.getSession(CART_KEY);
    },
    removeCartStorage() {
      Persistent.removeSession(CART_KEY);
    },
  },
});

export function useCartWithOut() {
  return useCartStore(store);
}
