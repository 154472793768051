import {
  formatPrice,
  formatPriceArr,
  formatPriceToYuanRoundDown,
  formatPercent,
} from "@/utils/format";
import { getCurrencySettingByCurrency } from "@/constant/currency";

export default function useCurrency() {
  const useFormatPercent = function (numArg, currency, isFormated = false) {
    const currencySetting = getCurrencySettingByCurrency(currency);
    return formatPercent(numArg, currencySetting.currency, isFormated);
  };
  const useFormatNumber = function (val, currency) {
    const currencySetting = getCurrencySettingByCurrency(currency);
    return val.toString().replace(".", currencySetting.decimal_separator);
  };
  const useFormatPrice = function (
    numArg,
    currency,
    isShowCurrencySymbol = true,
    custinizedDecimalPlaces
  ) {
    const currencySetting = getCurrencySettingByCurrency(currency);
    return formatPrice(
      numArg,
      currencySetting.currency,
      custinizedDecimalPlaces !== undefined
        ? custinizedDecimalPlaces
        : currencySetting.decimal_places,
      currencySetting.decimal_separator,
      isShowCurrencySymbol
    );
  };
  const useFormatPriceArr = function (numArgArray, currency) {
    const currencySetting = getCurrencySettingByCurrency(currency);
    return formatPriceArr(
      numArgArray,
      currencySetting.currency,
      currencySetting.decimal_places,
      currencySetting.decimal_separator,
      true
    );
  };
  // 按货币配置的显示位数，舍掉超出的位，不再参加运算
  const useRoundDownPriceByDecimalPlaces = function (numArg, currency) {
    const currencySetting = getCurrencySettingByCurrency(currency);
    if (isNaN(numArg)) {
      return numArg;
    }

    if (!Number.isInteger(numArg)) {
      numArg = parseInt(numArg);
    }

    if (currencySetting.decimal_places < 2) {
      let result = String(numArg);
      const filler = "".padStart(2 - currencySetting.decimal_places, "0");
      result = result.slice(0, -(2 - currencySetting.decimal_places)) + filler;
      return Number(result);
    } else {
      return numArg;
    }
  };
  const useFormatSalePrices = function (salePrices, currency) {
    if (Array.isArray(salePrices)) {
      return useFormatPriceArr(salePrices, currency);
    }
    return useFormatPrice(salePrices, currency);
  };
  const useFormatPriceToYuanRoundDown = function (
    numArg,
    currency,
    isShowCurrencySymbol = true
  ) {
    const currencySetting = getCurrencySettingByCurrency(currency);
    return formatPriceToYuanRoundDown(
      numArg,
      currencySetting.currency,
      currencySetting.decimal_places,
      currencySetting.decimal_separator,
      isShowCurrencySymbol
    );
  };

  /**
   * 按货币配置的小数位来取金额的分的值(numArg为分)
   * 小数位数是x,就把后面的2-x位切成0
   * 如印尼,输入12345,0位小数,则返回12300
   * 取法为向下取整
   */
  // const useFormatNumberFenByDecimalPlaces = function (numArg: number) {
  //   const divisor = Math.pow(10, 2 - currencySetting.decimal_places);
  //   const yuanNum = NP.divide(numArg, divisor);
  //   const result = NP.times(parseInt(yuanNum), divisor);
  //   return result;
  // };

  return {
    useFormatPercent,
    useFormatNumber,
    useFormatPrice,
    useFormatPriceArr,
    useRoundDownPriceByDecimalPlaces,
    useFormatSalePrices,
    useFormatPriceToYuanRoundDown,
  };
}
