import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue";
import _imports_0 from '@/assets/images/icons/close_btn.svg';
var _hoisted_1 = {
  key: 0,
  class: "dish-detail-wrap"
};
var _hoisted_2 = ["src"];
var _hoisted_3 = {
  class: "indicator-wrap"
};
var _hoisted_4 = {
  class: "no-img"
};
var _hoisted_5 = {
  class: "dish-detail-content"
};
var _hoisted_6 = {
  class: "dish-name"
};
var _hoisted_7 = {
  class: "dish-desc"
};
var _hoisted_8 = {
  key: 0,
  class: "error-msg"
};
var _hoisted_9 = {
  class: "option-item-text"
};
var _hoisted_10 = {
  key: 0,
  class: "choose-limit"
};
var _hoisted_11 = {
  key: 1,
  class: "error-msg"
};
var _hoisted_12 = {
  class: "option-item-text"
};
var _hoisted_13 = {
  key: 0,
  class: "weight-dish-tips"
};
var _hoisted_14 = {
  key: 1,
  class: "dish-detail-add-statics"
};
var _hoisted_15 = {
  class: "add-statics"
};
var _hoisted_16 = {
  class: "add-statics-info"
};
var _hoisted_17 = {
  class: "total-price"
};
var _hoisted_18 = {
  class: "hide-tag-list"
};
var _hoisted_19 = {
  key: 0,
  class: "counter-wrap"
};
var _hoisted_20 = {
  key: 0,
  class: "buttons-wrap"
};
import { computed, nextTick, ref, watch } from "vue";
import { createAddDishItem, genUniSelectedKey, calcDishTagList, calcDishPrice } from "@/composable/useDishes";
import Button from "../base/Button.vue";
import useCurrency from "@/composable/useCurrency";
import Counter from "../base/Counter.vue";
import { DishSpecStatusEnum } from "@/constant/dishes";
import Option from "../base/Option.vue";
import { DISH_STATUS } from "@/constant/dishes";
import { showToast } from "@/utils/toastUtil";
import NP from "number-precision";
import { useCartStore } from "@/store/cartStore";
import { cloneDeep } from "lodash-es";
import { useRouter } from "@/hooks/useRouter";
import { useShopStore } from "@/store/shopStore";
import { useUserStore } from "@/store/userStore";
import { OrderTypeEnum } from "@/constant/order";
import { ossStyleProcess } from "@/utils/tools";
import { changeOnStockExtraHandler } from "@op/shared";
export default {
  __name: 'DishDetail',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    targetDish: {
      type: Object,
      default: function _default() {}
    }
  },
  emits: ["update:show"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var _useCurrency = useCurrency(),
      useFormatPrice = _useCurrency.useFormatPrice;
    var cartStore = useCartStore();
    var shopStore = useShopStore();
    var userStore = useUserStore();
    var _useRouter = useRouter(),
      routerPush = _useRouter.routerPush;
    var props = __props;
    var emits = __emit;
    var showDishDetail = computed({
      get: function get() {
        return props.show;
      },
      set: function set(val) {
        emits("update:show", val);
      }
    });
    var isOpened = ref(false);
    var dish = ref(null);
    var isWeightDishes = computed(function () {
      var _dish$value;
      return (_dish$value = dish.value) === null || _dish$value === void 0 ? void 0 : _dish$value.is_weight_dishes;
    });
    var detailContentWrap = ref();
    var skuWrap = ref();
    var extraOptionsWrap = ref();
    var extraWrap = ref();
    watch(showDishDetail, function (val) {
      if (!val || !props.targetDish.id) {
        return;
      }
      dish.value = cloneDeep(createAddDishItem(props.targetDish));
      if (detailContentWrap.value) {
        nextTick(function () {
          detailContentWrap.value.scrollTop = 0;
        });
      }
    });
    var selectedSkuId = computed(function () {
      var _dish$value2;
      return (_dish$value2 = dish.value) === null || _dish$value2 === void 0 || (_dish$value2 = _dish$value2.selectedInfo) === null || _dish$value2 === void 0 ? void 0 : _dish$value2.skuId;
    });
    // const selectedExtraOptions = computed(() => {
    //   return dish.value?.selectedInfo?.selectedExtraOptions || [];
    // });
    // const selectedExtraNeedings = computed(() => {
    //   return dish.value?.selectedInfo?.selectedExtraNeedings || [];
    // });

    var selectedExtra = computed(function () {
      var _dish$value3;
      return ((_dish$value3 = dish.value) === null || _dish$value3 === void 0 || (_dish$value3 = _dish$value3.selectedInfo) === null || _dish$value3 === void 0 ? void 0 : _dish$value3.selectedExtra) || [];
    });
    function changeSkuIdHandler(skuId, status) {
      if (status === DISH_STATUS.IN_STOCK) {
        // 新逻辑
        dish.value.selecetedSkuId = skuId;
        dish.value.selectedInfo.skuId = skuId;
        // 当前选中的规格价格
        // const selectedSku =
        //   dish.value.sku_list?.find(
        //     (sku) => sku.id === dish.value.selectedInfo?.skuId
        //   ) || dish.value.sku_list[0];
        // 规格修改后，openPrice也修改
        // openPrice.value = selectedSku.sale_price;
        // openPriceStr.value = String(NP.divide(selectedSku.sale_price, 100));
        // initDishPrice();
      } else if (status === DISH_STATUS.OUT_STOCK) {
        showToast("Sold out");
      }
    }
    var changeExtraOptionsHandler = function changeExtraOptionsHandler(extraOptionsGroupIndex, extraOptionsItemIndex, status) {
      var dishValue = dish.value;
      if (status === DISH_STATUS.IN_STOCK) {
        // const dish = dishValue;
        // const extraOptionsGroup = dish.extra_option_list[extraOptionsGroupIndex];

        // const selectedExtraOptionsGroup =
        //   dish.selectedInfo.selectedExtraOptions[extraOptionsGroupIndex];
        // const hasChosen = Boolean(selectedExtraOptionsGroup[extraOptionsItemIndex]);
        // const chosenNum = selectedExtraOptionsGroup.filter(Boolean).length;

        // if (extraOptionsGroup.choose_limit) {
        //   if (hasChosen) {
        //     // 取消选中
        //     // chosenNum大于min_choose时允许取消选中
        //     if (chosenNum > extraOptionsGroup.choose_limit.min_choose) {
        //       dish.selectedInfo.selectedExtraOptions[extraOptionsGroupIndex][
        //         extraOptionsItemIndex
        //       ] = 0;
        //     }
        //   } else {
        //     // 选中
        //     if (extraOptionsGroup.choose_limit.max_choose === 1) {
        //       // 单选
        //       dish.selectedInfo.selectedExtraOptions[extraOptionsGroupIndex] = [];
        //       dish.selectedInfo.selectedExtraOptions[extraOptionsGroupIndex][
        //         extraOptionsItemIndex
        //       ] = 1;
        //     } else if (chosenNum < extraOptionsGroup.choose_limit.max_choose) {
        //       // 多选，chosenNum小于max_choose时允许选中
        //       dish.selectedInfo.selectedExtraOptions[extraOptionsGroupIndex][
        //         extraOptionsItemIndex
        //       ] = 1;
        //     }
        //   }
        // } else {
        //   // 没有choose_limit的可以自由选中、取消
        //   dish.selectedInfo.selectedExtraOptions[extraOptionsGroupIndex][
        //     extraOptionsItemIndex
        //   ] = Number(!Boolean(selectedExtraOptionsGroup[extraOptionsItemIndex]));
        // }
        changeOnStockExtraHandler(dishValue, extraOptionsGroupIndex, extraOptionsItemIndex);
      } else if (status === DISH_STATUS.OUT_STOCK) {
        showToast("Sold out");
      }
    };

    // 根据菜品选择信息计算菜品金额（菜品单价加上额外加料的总单价）
    var showPrice = computed(function () {
      var _dish$value4;
      return calcDishPrice(dish.value, (_dish$value4 = dish.value) === null || _dish$value4 === void 0 ? void 0 : _dish$value4.selectedInfo);
    });
    // 菜品单价*菜品数目
    var showPriceTimeNum = computed(function () {
      var _dish$value5;
      return NP.times(showPrice.value, (_dish$value5 = dish.value) === null || _dish$value5 === void 0 ? void 0 : _dish$value5.num);
    });
    // 根据菜品选择信息计算tagList
    var tagList = computed(function () {
      return calcDishTagList(dish.value);
    });
    var tagListStr = computed(function () {
      return tagList.value.map(function (item) {
        return item.value;
      }).filter(Boolean).join(", ");
    });
    function handleOrderNow() {
      handleConfirm(true);
    }
    // 校验必填项是否已选
    var showErrorMsg = ref(false);
    function validateSelectedInfo() {
      var isValid = true;
      var hasScroll = false;
      var selectedInfo = dish.value.selectedInfo;
      // const extraOptionList = dish.value.extra_option_list || [];
      var extraList = dish.value.modifier_add_ons_list || [];

      // 校验sku
      if (!selectedInfo.skuId) {
        isValid = false;
        if (!hasScroll && detailContentWrap.value && skuWrap.value) {
          hasScroll = true;
          detailContentWrap.value.scrollTo({
            top: skuWrap.value.offsetTop + 1,
            behavior: "smooth"
          });
        }
      }
      // 校验extraOptions
      // extraOptionList.forEach((item, index) => {
      //   if (
      //     item.choose_limit?.min_choose > 0 &&
      //     selectedInfo.selectedExtraOptions?.[index]?.filter(Boolean)?.length <
      //       item.choose_limit.min_choose
      //   ) {
      //     isValid = false;
      //     if (
      //       !hasScroll &&
      //       detailContentWrap.value &&
      //       extraOptionsWrap.value?.children?.[index]
      //     ) {
      //       hasScroll = true;
      //       detailContentWrap.value.scrollTo({
      //         top: extraOptionsWrap.value.children[index].offsetTop + 1,
      //         behavior: "smooth",
      //       });
      //     }
      //   }
      // });
      // 校验extraNeedings
      extraList.forEach(function (item, index) {
        var _item$choose_limit, _selectedInfo$selecte;
        if (((_item$choose_limit = item.choose_limit) === null || _item$choose_limit === void 0 ? void 0 : _item$choose_limit.min_choose) > 0 && ((_selectedInfo$selecte = selectedInfo.selectedExtra) === null || _selectedInfo$selecte === void 0 || (_selectedInfo$selecte = _selectedInfo$selecte[index]) === null || _selectedInfo$selecte === void 0 || (_selectedInfo$selecte = _selectedInfo$selecte.filter(Boolean)) === null || _selectedInfo$selecte === void 0 ? void 0 : _selectedInfo$selecte.length) < item.choose_limit.min_choose) {
          var _extraWrap$value;
          isValid = false;
          if (!hasScroll && detailContentWrap.value && (_extraWrap$value = extraWrap.value) !== null && _extraWrap$value !== void 0 && (_extraWrap$value = _extraWrap$value.children) !== null && _extraWrap$value !== void 0 && _extraWrap$value[index]) {
            hasScroll = true;
            detailContentWrap.value.scrollTo({
              top: extraWrap.value.children[index].offsetTop + 1,
              behavior: "smooth"
            });
          }
        }
      });
      if (isValid) {
        showErrorMsg.value = false;
      } else {
        showErrorMsg.value = true;
      }
      return isValid;
    }
    // 加入购物车
    function handleConfirm() {
      var isOrderNow = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var res = validateSelectedInfo();
      if (!res) {
        return;
      }
      // 加上当前open_price的值
      // dish.value.open_price = openPrice.value;
      // 重量值修改
      // dish.value.is_weight_dishes && (dish.value.weight = weight.value);
      // 当前选中的值等于showPrice的值
      dish.value.selectedPrice = showPrice.value;
      dish.value.UUKey = genUniSelectedKey(dish.value);
      cartStore.addItem(dish.value);
      handleClose();
      if (isOrderNow) {
        setTimeout(function () {
          routerPush({
            name: "ConfirmOrder"
          });
        }, 300);
      }
    }
    function handleClose() {
      showDishDetail.value = false;
    }
    var startingPrice = computed(function () {
      var _shopStore$getShopDat;
      return ((_shopStore$getShopDat = shopStore.getShopData) === null || _shopStore$getShopDat === void 0 || (_shopStore$getShopDat = _shopStore$getShopDat.self_delivery_setting) === null || _shopStore$getShopDat === void 0 ? void 0 : _shopStore$getShopDat.starting_price) || 0;
    });
    var disableGoConfirmOrder = computed(function () {
      return userStore.getOrderType === OrderTypeEnum.SELF_DELIVERY && cartStore.getCartTotalPrice + showPriceTimeNum.value < startingPrice.value;
    });
    return function (_ctx, _cache) {
      var _component_van_swipe_item = _resolveComponent("van-swipe-item");
      var _component_van_swipe = _resolveComponent("van-swipe");
      var _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createBlock(_component_van_popup, {
        show: showDishDetail.value,
        "onUpdate:show": _cache[2] || (_cache[2] = function ($event) {
          return showDishDetail.value = $event;
        }),
        "safe-area-inset-bottom": "",
        position: "bottom",
        round: "",
        style: {
          'overflow-y': 'hidden'
        },
        onOpened: _cache[3] || (_cache[3] = function ($event) {
          return isOpened.value = true;
        }),
        onClose: _cache[4] || (_cache[4] = function ($event) {
          return isOpened.value = false;
        })
      }, {
        default: _withCtx(function () {
          var _dish$value$imgs, _dish$value$imgs2, _dish$value6;
          return [dish.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
            class: "icon-close-wrap",
            onClick: handleClose
          }, _cache[5] || (_cache[5] = [_createElementVNode("img", {
            class: "icon-close",
            src: _imports_0,
            alt: ""
          }, null, -1)])), _createElementVNode("div", {
            ref_key: "detailContentWrap",
            ref: detailContentWrap,
            class: "dish-detail-content-wrap"
          }, [_withDirectives(_createVNode(_component_van_swipe, {
            class: "swipe-wrap",
            loop: false
          }, {
            indicator: _withCtx(function (_ref2) {
              var active = _ref2.active,
                total = _ref2.total;
              return [_withDirectives(_createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(total, function (item) {
                return _openBlock(), _createElementBlock("div", {
                  key: item,
                  class: _normalizeClass(["indicator-dot", {
                    'indicator-dot--active': active === item - 1
                  }])
                }, null, 2);
              }), 128))], 512), [[_vShow, total > 1]])];
            }),
            default: _withCtx(function () {
              return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dish.value.imgs, function (img, index) {
                return _openBlock(), _createBlock(_component_van_swipe_item, {
                  key: img,
                  class: _normalizeClass(["dish-img-wrap", {
                    active: isOpened.value && index === 0
                  }])
                }, {
                  default: _withCtx(function () {
                    return [_createElementVNode("img", {
                      class: "dish-img",
                      src: _unref(ossStyleProcess)(img, 'type_23'),
                      alt: ""
                    }, null, 8, _hoisted_2)];
                  }),
                  _: 2
                }, 1032, ["class"]);
              }), 128))];
            }),
            _: 1
          }, 512), [[_vShow, (_dish$value$imgs = dish.value.imgs) === null || _dish$value$imgs === void 0 ? void 0 : _dish$value$imgs.length]]), _withDirectives(_createElementVNode("div", _hoisted_4, null, 512), [[_vShow, !((_dish$value$imgs2 = dish.value.imgs) !== null && _dish$value$imgs2 !== void 0 && _dish$value$imgs2[0])]]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(dish.value.name), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(dish.value.description), 1), dish.value.spec_type !== _unref(DishSpecStatusEnum).SINGLE ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref_key: "skuWrap",
            ref: skuWrap,
            class: "options-section"
          }, [_createElementVNode("div", {
            class: _normalizeClass(["options-section-name form-item--require", {
              'opacity-40': isWeightDishes.value
            }])
          }, [_cache[6] || (_cache[6] = _createElementVNode("span", null, "Specification", -1)), showErrorMsg.value && !selectedSkuId.value ? (_openBlock(), _createElementBlock("span", _hoisted_8, "(Please choose)")) : _createCommentVNode("", true)], 2), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dish.value.sku_list, function (sku) {
            return _openBlock(), _createBlock(Option, {
              key: sku.id,
              class: "option-item",
              gap: 8,
              "is-selected": selectedSkuId.value === sku.id,
              "is-disabled": sku.stock_status === _unref(DISH_STATUS).OUT_STOCK || isWeightDishes.value,
              onClick: function onClick($event) {
                return changeSkuIdHandler(sku.id, sku.stock_status);
              }
            }, {
              default: _withCtx(function () {
                var _sku$spec;
                return [_createElementVNode("div", _hoisted_9, _toDisplayString((_sku$spec = sku.spec) === null || _sku$spec === void 0 ? void 0 : _sku$spec.name), 1)];
              }),
              _: 2
            }, 1032, ["is-selected", "is-disabled", "onClick"]);
          }), 128))])], 512)) : _createCommentVNode("", true), _createElementVNode("div", {
            ref_key: "extraWrap",
            ref: extraWrap
          }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_dish$value6 = dish.value) === null || _dish$value6 === void 0 ? void 0 : _dish$value6.modifier_add_ons_list, function (extraGroup, extraGroupIndex) {
            var _extraGroup$choose_li, _extraGroup$choose_li2, _extraGroup$choose_li3;
            return _openBlock(), _createElementBlock("div", {
              key: extraGroup.id,
              class: "options-section"
            }, [_createElementVNode("div", {
              class: _normalizeClass(["options-section-name", {
                'form-item--require': ((_extraGroup$choose_li = extraGroup.choose_limit) === null || _extraGroup$choose_li === void 0 ? void 0 : _extraGroup$choose_li.min_choose) > 0,
                'opacity-40': isWeightDishes.value
              }])
            }, [_createElementVNode("span", null, _toDisplayString(extraGroup.name), 1), ((_extraGroup$choose_li2 = extraGroup.choose_limit) === null || _extraGroup$choose_li2 === void 0 ? void 0 : _extraGroup$choose_li2.max_choose) > 1 ? (_openBlock(), _createElementBlock("span", _hoisted_10, "(Up to " + _toDisplayString(extraGroup.choose_limit.max_choose) + " options available)", 1)) : _createCommentVNode("", true), showErrorMsg.value && ((_extraGroup$choose_li3 = extraGroup.choose_limit) === null || _extraGroup$choose_li3 === void 0 ? void 0 : _extraGroup$choose_li3.min_choose) > 0 && selectedExtra.value[extraGroupIndex].filter(Boolean).length < extraGroup.choose_limit.min_choose ? (_openBlock(), _createElementBlock("span", _hoisted_11, "(Please choose)")) : _createCommentVNode("", true)], 2), _createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(extraGroup.values, function (extraItem, extraItemIndex) {
              var _selectedExtra$value;
              return _openBlock(), _createBlock(Option, {
                key: extraItem.id,
                class: "option-item",
                gap: 8,
                "is-selected": selectedExtra.value && ((_selectedExtra$value = selectedExtra.value) === null || _selectedExtra$value === void 0 ? void 0 : _selectedExtra$value[extraGroupIndex][extraItemIndex]) === 1,
                "is-disabled": extraItem.stock_status === _unref(DISH_STATUS).OUT_STOCK || isWeightDishes.value,
                onClick: function onClick($event) {
                  return changeExtraOptionsHandler(extraGroupIndex, extraItemIndex, extraItem.stock_status);
                }
              }, {
                default: _withCtx(function () {
                  return [_createElementVNode("div", _hoisted_12, _toDisplayString(extraItem.name) + " " + _toDisplayString(extraGroup.type === "needing" ? _unref(useFormatPrice)(extraItem.sale_price, extraItem.currency) : ""), 1)];
                }),
                _: 2
              }, 1032, ["is-selected", "is-disabled", "onClick"]);
            }), 128))])]);
          }), 128))], 512)])], 512), isWeightDishes.value ? (_openBlock(), _createElementBlock("div", _hoisted_13, [_cache[8] || (_cache[8] = _createElementVNode("div", {
            class: "tips-info"
          }, [_createElementVNode("div", null, "This product is sold by weight."), _createElementVNode("div", null, "Please contact the server to place an order")], -1)), _createVNode(Button, {
            class: "confirm-btn",
            onClick: handleClose
          }, {
            default: _withCtx(function () {
              return _cache[7] || (_cache[7] = [_createTextVNode("OK")]);
            }),
            _: 1
          })])) : (_openBlock(), _createElementBlock("div", _hoisted_14, [_createElementVNode("div", _hoisted_15, [_createElementVNode("div", _hoisted_16, [_createElementVNode("div", _hoisted_17, _toDisplayString(_unref(useFormatPrice)(showPriceTimeNum.value, dish.value.currency)), 1), _createElementVNode("div", _hoisted_18, _toDisplayString(tagList.value), 1), _withDirectives(_createElementVNode("div", {
            class: "selected-info van-multi-ellipsis--l2"
          }, _toDisplayString(tagListStr.value), 513), [[_vShow, tagListStr.value]])]), !_unref(shopStore).getIsShopClosed ? (_openBlock(), _createElementBlock("div", _hoisted_19, [_createVNode(Counter, {
            count: dish.value.num,
            "onUpdate:count": _cache[0] || (_cache[0] = function ($event) {
              return dish.value.num = $event;
            }),
            min: 1,
            "allow-input": ""
          }, null, 8, ["count"])])) : _createCommentVNode("", true)]), !_unref(shopStore).getIsShopClosed ? (_openBlock(), _createElementBlock("div", _hoisted_20, [_createVNode(Button, {
            class: "button-item",
            type: "border",
            disabled: disableGoConfirmOrder.value,
            onClick: handleOrderNow
          }, {
            default: _withCtx(function () {
              return _cache[9] || (_cache[9] = [_createTextVNode("Order Now")]);
            }),
            _: 1
          }, 8, ["disabled"]), _createVNode(Button, {
            class: "button-item",
            onClick: _cache[1] || (_cache[1] = function ($event) {
              return handleConfirm(false);
            })
          }, {
            default: _withCtx(function () {
              return _cache[10] || (_cache[10] = [_createTextVNode("Add to Bag")]);
            }),
            _: 1
          })])) : _createCommentVNode("", true)]))])) : _createCommentVNode("", true)];
        }),
        _: 1
      }, 8, ["show"]);
    };
  }
};