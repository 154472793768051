import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.reduce.js";
import "core-js/modules/es.object.to-string.js";
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  class: "menu-dish-img-wrap"
};
var _hoisted_2 = {
  class: "menu-dish-info"
};
var _hoisted_3 = {
  key: 0,
  class: "open-price"
};
var _hoisted_4 = {
  key: 0
};
import { computed } from "vue";
import useCurrency from "@/composable/useCurrency";
import { useShopStoreWithOut } from "@/store/shopStore";
import { useCartStore } from "@/store/cartStore";
import { DISH_STATUS } from "@/constant/dishes";
import Image from "../base/Image.vue";
export default {
  __name: 'MenuDish',
  props: {
    dishItem: {
      type: Object,
      default: function _default() {}
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  setup: function setup(__props) {
    var props = __props;
    var shopStore = useShopStoreWithOut();
    var cartStore = useCartStore();
    var _useCurrency = useCurrency(),
      useFormatPriceArr = _useCurrency.useFormatPriceArr;
    var isTR = computed(function () {
      return shopStore.getCountry === "TR";
    });
    var showPrice = computed(function () {
      var _priceArr;
      var priceArr = props.dishItem.sale_price_display;
      if (!((_priceArr = priceArr) !== null && _priceArr !== void 0 && _priceArr.length)) return "";
      if (isTR.value) {
        priceArr = [priceArr[0]];
      }
      return useFormatPriceArr(priceArr, props.dishItem.currency);
    });
    var cartNum = computed(function () {
      return cartStore.getCart.filter(function (item) {
        return item.id === props.dishItem.id;
      }).reduce(function (pre, dish) {
        return pre += dish.num;
      }, 0);
    });
    return function (_ctx, _cache) {
      var _props$dishItem$imgs;
      return _openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["menu-dish", {
          hovering: __props.active
        }])
      }, [_createElementVNode("div", _hoisted_1, [_createVNode(Image, {
        src: (_props$dishItem$imgs = __props.dishItem.imgs) === null || _props$dishItem$imgs === void 0 ? void 0 : _props$dishItem$imgs[0],
        class: _normalizeClass(["menu-dish-img", {
          'menu-dish-img--soldout': __props.dishItem.stock_status === _unref(DISH_STATUS).OUT_STOCK
        }]),
        alt: "",
        "is-fade-in": true,
        "lazy-load": true,
        "oss-stylename": "type_3"
      }, null, 8, ["src", "class"]), _withDirectives(_createElementVNode("div", {
        class: "cart-num-badge"
      }, _toDisplayString(cartNum.value), 513), [[_vShow, cartNum.value > 0]])]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", {
        class: _normalizeClass(["name", {
          'name--soldout': __props.dishItem.stock_status === _unref(DISH_STATUS).OUT_STOCK
        }])
      }, _toDisplayString(__props.dishItem.name), 3), _createElementVNode("div", null, [__props.dishItem.is_open_price ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Open price")) : _createCommentVNode("", true), _createElementVNode("div", {
        class: _normalizeClass(["price", {
          'price--soldout': __props.dishItem.stock_status === _unref(DISH_STATUS).OUT_STOCK
        }])
      }, [_createElementVNode("span", null, _toDisplayString(showPrice.value), 1), __props.dishItem.is_weight_dishes ? (_openBlock(), _createElementBlock("span", _hoisted_4, "/" + _toDisplayString(__props.dishItem.weight_unit), 1)) : _createCommentVNode("", true)], 2)])])], 2);
    };
  }
};