import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue";
import _imports_0 from '@/assets/images/icons/icon_check.svg';
import _imports_1 from '@/assets/images/icons/icon_checked.svg';
var _hoisted_1 = {
  class: "payment-method-list"
};
var _hoisted_2 = ["onClick"];
var _hoisted_3 = ["src"];
var _hoisted_4 = {
  key: 0,
  class: "red"
};
var _hoisted_5 = {
  class: "right-icon",
  src: _imports_0,
  alt: ""
};
var _hoisted_6 = {
  class: "right-icon",
  src: _imports_1,
  alt: ""
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_SvgIcon = _resolveComponent("SvgIcon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.optionsShow, function (item, index) {
    return _openBlock(), _createElementBlock("div", {
      key: item.value,
      class: _normalizeClass(["hidden", {
        'item-wrap': item.method === 'cod' && ($options.onlinePaymentOptions.length === 0 || $data.isShowMore) || item.method !== 'cod'
      }])
    }, [_createElementVNode("div", {
      class: _normalizeClass(['card-border', 'item']),
      onClick: function onClick($event) {
        return $options.selectHandler(index);
      }
    }, [item.leftIcon ? (_openBlock(), _createElementBlock("img", {
      key: 0,
      src: item.leftIcon,
      alt: "",
      class: "left-icon"
    }, null, 8, _hoisted_3)) : _createCommentVNode("", true), _createElementVNode("div", null, [_createTextVNode(_toDisplayString(item.text) + " ", 1), item.method === 'balance' ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString("(".concat($options.useFormatPrice(_ctx.userStore.getWallet, _ctx.userStore.getWalletCurrency), ")")), 1)) : _createCommentVNode("", true)]), _withDirectives(_createElementVNode("img", _hoisted_5, null, 512), [[_vShow, $data.selectedIndex !== index]]), _withDirectives(_createElementVNode("img", _hoisted_6, null, 512), [[_vShow, $data.selectedIndex === index]])], 8, _hoisted_2)], 2);
  }), 128)), !$data.isShowMore && $options.onlinePaymentOptions.length && $options.codPaymentOptions.length ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "more-wrap",
    onClick: _cache[0] || (_cache[0] = function () {
      return $options.handleShowMore && $options.handleShowMore.apply($options, arguments);
    })
  }, [_cache[1] || (_cache[1] = _createElementVNode("div", null, "More", -1)), _createVNode(_component_SvgIcon, {
    name: "drop_down",
    class: "icon-drop-down"
  })])) : _createCommentVNode("", true)]);
}