import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "comp-counter"
};
import { ref, computed, nextTick } from "vue";
import SvgIcon from "@/components/base/SvgIcon.vue";
import Common from "@/components/dialog/src/CommonCenter.vue";
import { Field } from "vant";
import Dialog from "@/components/dialog/dialog";
export default {
  __name: 'Counter',
  props: {
    count: {
      type: Number,
      required: true
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 99
    },
    size: {
      type: String,
      default: "large"
    },
    allowInput: {
      type: Boolean,
      default: false
    },
    confirmDeleteOptions: {
      type: Object,
      default: function _default() {
        return null;
      }
    }
  },
  emits: ["update:count"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var props = __props;
    var counterSizes = {
      large: {
        iconSize: 36,
        textClass: "text--large"
      },
      medium: {
        iconSize: 28,
        textClass: "text--medium"
      }
    };
    var currentCounterSize = computed(function () {
      var size = counterSizes.hasOwnProperty(props.size) ? props.size : "medium";
      return counterSizes[size];
    });
    var emit = __emit;
    var computedCount = computed({
      get: function get() {
        return props.count;
      },
      set: function set(val) {
        emit("update:count", val);
      }
    });
    var isMax = computed(function () {
      return computedCount.value >= props.max;
    });
    var isMin = computed(function () {
      return computedCount.value <= props.min;
    });
    function reduce() {
      if (isMin.value) {
        return;
      }
      new Promise(function (resolve) {
        if (computedCount.value === 1 && props.confirmDeleteOptions) {
          Dialog.confirm({
            title: props.confirmDeleteOptions.title,
            desc: props.confirmDeleteOptions.desc,
            okText: props.confirmDeleteOptions.okText || "Yes",
            cancelText: props.confirmDeleteOptions.cancelText || "No",
            onOk: function onOk() {
              resolve(true);
            },
            onCancel: function onCancel() {
              resolve(false);
            }
          });
        } else {
          resolve(true);
        }
      }).then(function (res) {
        if (res) {
          computedCount.value -= 1;
        }
      });
    }
    function add() {
      if (isMax.value) {
        return;
      }
      computedCount.value += 1;
    }
    var changeNum = ref("");
    var isChangeNumDialogVisible = ref(false);
    var changeNumInput = ref();
    var isChangeNumInputFocused = ref(false);
    function handleClickCount() {
      if (!props.allowInput) {
        return;
      }
      changeNum.value = String(computedCount.value);
      isChangeNumDialogVisible.value = true;
      nextTick(function () {
        var _changeNumInput$value;
        (_changeNumInput$value = changeNumInput.value) === null || _changeNumInput$value === void 0 || _changeNumInput$value.focus();
      });
    }
    function handleUpdateChangeNum(val) {
      changeNum.value = val;
      nextTick(function () {
        var value = changeNum.value;
        // 过滤非法字符
        value = value.replace(/[^\d]/g, "");
        // 禁止多次输入0
        value = value === "00" ? "0" : value;
        // 不能超过最大值
        if (Number(value) >= props.max) {
          value = String(props.max);
        }
        // 不能小于最小值
        if (value !== "" && Number(value) <= props.min) {
          value = String(props.min);
        }
        changeNum.value = value;
      });
    }
    function handleConfirm() {
      var val = Number(changeNum.value);
      if (val >= props.max) {
        val = props.max;
      } else if (val <= props.min) {
        val = props.min;
      }
      new Promise(function (resolve) {
        if (val === 0 && props.confirmDeleteOptions) {
          Dialog.confirm({
            title: props.confirmDeleteOptions.title,
            desc: props.confirmDeleteOptions.desc,
            okText: props.confirmDeleteOptions.okText || "Yes",
            cancelText: props.confirmDeleteOptions.cancelText || "No",
            onOk: function onOk() {
              resolve(true);
            },
            onCancel: function onCancel() {
              resolve(false);
            }
          });
        } else {
          resolve(true);
        }
      }).then(function (res) {
        if (res) {
          isChangeNumDialogVisible.value = false;
          setTimeout(function () {
            computedCount.value = val;
          }, 100);
        }
      });
    }
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(SvgIcon, {
        name: "minus_btn",
        size: currentCounterSize.value.iconSize,
        class: "minus-btn",
        onClick: reduce
      }, null, 8, ["size"]), _createElementVNode("div", {
        class: _normalizeClass(["text-class", currentCounterSize.value.textClass]),
        onClick: handleClickCount
      }, _toDisplayString(computedCount.value), 3), _createVNode(SvgIcon, {
        name: "plus_btn",
        size: currentCounterSize.value.iconSize,
        class: "plus-btn",
        onClick: add
      }, null, 8, ["size"]), _createVNode(Common, {
        visible: isChangeNumDialogVisible.value,
        "onUpdate:visible": _cache[2] || (_cache[2] = function ($event) {
          return isChangeNumDialogVisible.value = $event;
        }),
        title: 'Please Enter Quantity',
        "on-ok": handleConfirm
      }, {
        content: _withCtx(function () {
          return [_createElementVNode("div", {
            class: _normalizeClass(["change-num-input-wrap", {
              'change-num-input-warp--focused': isChangeNumInputFocused.value
            }])
          }, [_createVNode(_unref(Field), {
            ref_key: "changeNumInput",
            ref: changeNumInput,
            "model-value": changeNum.value,
            autocomplete: "off",
            "onUpdate:modelValue": handleUpdateChangeNum,
            onFocus: _cache[0] || (_cache[0] = function ($event) {
              return isChangeNumInputFocused.value = true;
            }),
            onBlur: _cache[1] || (_cache[1] = function ($event) {
              return isChangeNumInputFocused.value = false;
            })
          }, null, 8, ["model-value"])], 2)];
        }),
        _: 1
      }, 8, ["visible"])]);
    };
  }
};