import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
var _hoisted_1 = {
  key: 0,
  class: "menu-cart"
};
var _hoisted_2 = {
  class: "starting-price-tips"
};
var _hoisted_3 = {
  class: "starting-price"
};
var _hoisted_4 = {
  class: "cart-statics"
};
var _hoisted_5 = {
  class: "icon-drink-wrap"
};
var _hoisted_6 = {
  class: "cart-statics-content"
};
var _hoisted_7 = {
  class: "cart-total-price"
};
var _hoisted_8 = {
  class: "cart-length"
};
import { computed, ref } from "vue";
import CartList from "./CartList.vue";
import Button from "../base/Button.vue";
import SvgIcon from "../base/SvgIcon.vue";
import useCurrency from "@/composable/useCurrency";
import { useCartStore } from "@/store/cartStore";
import { useRouter } from "@/hooks/useRouter";
import { useShopStore } from "@/store/shopStore";
import { OrderTypeEnum } from "@/constant/order";
import { useUserStore } from "@/store/userStore";
export default {
  __name: 'MenuCart',
  setup: function setup(__props) {
    var _useCurrency = useCurrency(),
      useFormatPrice = _useCurrency.useFormatPrice;
    var cartStore = useCartStore();
    var _useRouter = useRouter(),
      routerPush = _useRouter.routerPush;
    var shopStore = useShopStore();
    var userStore = useUserStore();
    var startingPrice = computed(function () {
      var _shopStore$getShopDat;
      return ((_shopStore$getShopDat = shopStore.getShopData) === null || _shopStore$getShopDat === void 0 || (_shopStore$getShopDat = _shopStore$getShopDat.self_delivery_setting) === null || _shopStore$getShopDat === void 0 ? void 0 : _shopStore$getShopDat.starting_price) || 0;
    });
    var disableGoConfirmOrder = computed(function () {
      return userStore.getOrderType === OrderTypeEnum.SELF_DELIVERY && cartStore.getCartLength > 0 && cartStore.getCartTotalPrice < startingPrice.value;
    });
    var showCartList = ref(false);
    function handleShowCartList() {
      showCartList.value = !showCartList.value;
    }
    function goConfirmOrder() {
      routerPush({
        name: "ConfirmOrder"
      });
    }
    return function (_ctx, _cache) {
      return !_unref(shopStore).getIsShopClosed ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createElementVNode("div", _hoisted_2, [_createElementVNode("span", _hoisted_3, _toDisplayString(_unref(useFormatPrice)(startingPrice.value, _unref(cartStore).getCartItemCurrency)), 1), _cache[2] || (_cache[2] = _createElementVNode("span", {
        class: "tips-info"
      }, "minimum for delivery orders", -1))], 512), [[_vShow, disableGoConfirmOrder.value]]), _withDirectives(_createElementVNode("div", {
        class: "cart-info",
        onClick: handleShowCartList
      }, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(SvgIcon, {
        class: "icon-drink",
        name: "drink"
      })]), _createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString(_unref(useFormatPrice)(_unref(cartStore).getCartTotalPrice, _unref(cartStore).getCartItemCurrency)), 1), _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(cartStore).getCartTotalDishNum) + " item in the bag ", 1)])]), _createElementVNode("div", {
        class: "button-wrap",
        onClick: _cache[0] || (_cache[0] = _withModifiers(function () {}, ["stop"]))
      }, [_createVNode(Button, {
        class: "button-order-now",
        disabled: disableGoConfirmOrder.value,
        onClick: _withModifiers(goConfirmOrder, ["stop"])
      }, {
        default: _withCtx(function () {
          return _cache[3] || (_cache[3] = [_createTextVNode("Order Now")]);
        }),
        _: 1
      }, 8, ["disabled"])])], 512), [[_vShow, _unref(cartStore).getCartLength]]), _createVNode(CartList, {
        show: showCartList.value,
        "onUpdate:show": _cache[1] || (_cache[1] = function ($event) {
          return showCartList.value = $event;
        }),
        "disable-go-confirm-order": disableGoConfirmOrder.value
      }, null, 8, ["show", "disable-go-confirm-order"])])) : _createCommentVNode("", true);
    };
  }
};