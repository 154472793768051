import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.to-string.js";
import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "cart-item"
};
var _hoisted_2 = {
  class: "cart-item-img-wrap"
};
var _hoisted_3 = {
  class: "cart-item-content"
};
var _hoisted_4 = {
  class: "cart-item-base-info"
};
var _hoisted_5 = {
  class: "cart-item-name van-multi-ellipsis--l2"
};
var _hoisted_6 = {
  class: "cart-item-tag-list van-multi-ellipsis--l2"
};
var _hoisted_7 = {
  class: "cart-item-statics"
};
var _hoisted_8 = {
  class: "cart-item-price"
};
import useCurrency from "@/composable/useCurrency";
import NP from "number-precision";
import { computed } from "vue";
import { calcDishTagList } from "@/composable/useDishes";
import Counter from "../base/Counter.vue";
import Image from "../base/Image.vue";
export default {
  __name: 'CartItem',
  props: {
    cart: {
      type: Object,
      default: function _default() {}
    }
  },
  emits: ["update:count"],
  setup: function setup(__props, _ref) {
    var __emit = _ref.emit;
    var _useCurrency = useCurrency(),
      useFormatPrice = _useCurrency.useFormatPrice;
    var props = __props;
    var emits = __emit;

    // 根据购物车菜品选择信息计算tagList
    var tagList = computed(function () {
      return calcDishTagList(props.cart);
    });
    var tagListStr = computed(function () {
      return tagList.value.map(function (item) {
        return item.value;
      }).filter(Boolean).join(", ");
    });
    var cartItemTotalPrice = computed(function () {
      return NP.times(props.cart.selectedPrice, props.cart.num);
    });
    function handleUpdateCount(val) {
      emits("update:count", val);
    }
    return function (_ctx, _cache) {
      var _props$cart$imgs;
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(Image, {
        class: "cart-item-img",
        "oss-stylename": "type_3",
        src: (_props$cart$imgs = __props.cart.imgs) === null || _props$cart$imgs === void 0 ? void 0 : _props$cart$imgs[0],
        alt: ""
      }, null, 8, ["src"])]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, _toDisplayString(__props.cart.name), 1), _createElementVNode("div", _hoisted_6, _toDisplayString(tagListStr.value), 1)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", _hoisted_8, _toDisplayString(_unref(useFormatPrice)(cartItemTotalPrice.value, __props.cart.currency)), 1), _createVNode(Counter, {
        class: "cart-item-counter",
        count: __props.cart.num,
        size: "medium",
        "onUpdate:count": handleUpdateCount
      }, null, 8, ["count"])])])]);
    };
  }
};