import NP from "number-precision";
import {
  frontPercentCurrency,
  DecimalSeparator,
  PriceStrCurrencySymbolEnum,
} from "@/constant/currency";
import { toDayBusinessHoursMap } from "./tools";
import dayjs from "dayjs";
import useCurrency from "@/composable/useCurrency";
import { DiscountTypeEnum, UsingTimeRuleTypeEnum } from "@/constant/coupon";
import { cloneDeep } from "lodash-es";
import { isNumber } from "./isUtil";
/**
 * 获取三三分隔符
 * 三三分隔符与小数点分隔符对应区分，“,”“.”相互对应
 * @param decimalSeparator 小数点区分符
 */
export function getFormatPriceSpliter(decimalSeparator) {
  return decimalSeparator === DecimalSeparator.DOT
    ? DecimalSeparator.COMMA
    : DecimalSeparator.DOT;
}

/**
 * 价格格式化
 * @param numArg 原价格数值
 * @param currency 货币代码
 * @param decimalPlaces 小数点后位数
 * @param decimalSeparator 小数点区分符
 * @returns 格式化后的字符串
 */
export function formatPrice(
  numArg = 0,
  currency,
  decimalPlaces,
  decimalSeparator,
  isShowCurrencySymbol
) {
  let result;
  const numberNumArg = Number(numArg);
  const isMinus = numberNumArg < 0;
  const num = NP.divide(isMinus ? -numberNumArg : numberNumArg, 100);
  if (num) {
    // 获取字符串，分隔开整数和小数部分
    const numStr = num.toString();
    const numStrArr = numStr.split(".");
    result = numStrArr[0]; // 赋予整数部分
    let dec = numStrArr[1] || ""; // 小数部分变量
    // 整数部分，三三分隔
    result = result.replace(/\d+/, function (s) {
      return s.replace(
        /(\d)(?=(\d{3})+$)/g,
        `$1${getFormatPriceSpliter(decimalSeparator)}`
      );
    });

    // 小数部分
    const isShowDecimals = decimalPlaces > 0;
    if (isShowDecimals) {
      if (dec) {
        // 有小数部分的操作
        const leftNum = decimalPlaces - dec.length;
        if (leftNum > 0) {
          for (let i = 0; i < leftNum; i++) {
            dec = dec + "0";
          }
        }
      } else {
        for (let i = 0; i < decimalPlaces; i++) {
          dec = dec + "0";
        }
      }
      result = result + decimalSeparator + dec;
    }
  } else {
    result = String(num);
  }

  if (isShowCurrencySymbol) {
    // 货币符号
    const currencySymbol = PriceStrCurrencySymbolEnum[currency] || currency;
    if (currencySymbol === currency) {
      result = currencySymbol + " " + result;
    } else if (currencySymbol?.startsWith(" ")) {
      // 以空格开头，说明这个货币符号是放在末尾的
      result = result + currencySymbol;
    } else {
      // 否则货币符号放在开头
      result = currencySymbol + result;
    }
  }

  if (isMinus) {
    result = "-" + result;
  }

  return result;
}

/**
 * 价格区间格式化(注意与@/utils/currency的方法同步)
 * @param {*} numArr 原价格数值数组，接受1~2长度数组
 * @param {*} currency 货币代码
 * @param {*} decimalPlaces 小数点后位数
 * @param {*} decimalSeparator 小数点区分符
 * @returns 格式化后的字符串
 */
export function formatPriceArr(
  numArr = [0],
  currency,
  decimalPlaces,
  decimalSeparator,
  isShowCurrencySymbol
) {
  if (numArr.length === 1) {
    return formatPrice(
      numArr[0],
      currency,
      decimalPlaces,
      decimalSeparator,
      true
    );
  } else {
    const startPrice = formatPrice(
      numArr[0],
      currency,
      decimalPlaces,
      decimalSeparator,
      false
    );
    const endPrice = formatPrice(
      numArr[1],
      currency,
      decimalPlaces,
      decimalSeparator,
      false
    );
    let result = `${startPrice}~${endPrice}`;
    // 如不传入currency,则不显示货币符号(目前gcp返利显示使用该逻辑)
    if (isShowCurrencySymbol) {
      // 货币符号
      const currencySymbol = PriceStrCurrencySymbolEnum[currency] || currency;
      if (currencySymbol === currency) {
        result = currencySymbol + " " + result;
      } else if (currencySymbol.startsWith(" ")) {
        // 以空格开头，说明这个货币符号是放在末尾的
        result = result + currencySymbol;
      } else {
        // 否则货币符号放在开头
        result = currencySymbol + result;
      }
    }
    return result;
  }
}

/**
 * 抹零价格格式化
 * @param numArg 原价格数值
 * @param currency 货币代码
 * @param isShowCurrencySymbol 是否需要显示货币符号
 * @returns 格式化后的字符串
 */
export function formatPriceToYuanRoundDown(
  numArg = 0,
  currency,
  decimalPlaces,
  decimalSeparator,
  isShowCurrencySymbol
) {
  return numArg >= 0
    ? "-" +
        formatPrice(
          numArg,
          currency,
          decimalPlaces,
          decimalSeparator,
          isShowCurrencySymbol
        )
    : "+" +
        formatPrice(
          numArg * -1,
          currency,
          decimalPlaces,
          decimalSeparator,
          isShowCurrencySymbol
        );
}

/**
 * 百分比展示转化
 * @param val 小数展示，如 0.12
 * @param currency
 * @param isFormated 0.12 是否已转换为 12
 * @returns '12%'
 */
export function formatPercent(val = 0, currency, isFormated = false) {
  if (isNaN(Number(val))) return "";
  let result;
  if (isFormated) {
    result = val;
  } else {
    result = String(NP.times(val, 100));
  }
  if (frontPercentCurrency.includes(currency)) {
    result = "%" + result;
  } else {
    result = result + "%";
  }
  return result;
}

// 格式化地址对象的方法(针对location这一级)
export function formatLocation(location) {
  if (!location) {
    return "";
  }
  let detail = location.detail || "";
  if (
    detail.length > 30 &&
    location.country &&
    location.state &&
    location.city
  ) {
    // 防止detail写得过长,超过30位则切除
    detail = detail.substring(0, 26) + "...";
  }
  const resArr = [
    location.unit_no,
    detail,
    location.site || "",
    location.street || "",
    location.district || "",
    [location.postcode, location.city].filter((item) => !!item).join(" ") || "",
    location.state || "",
    location.country || "",
  ];
  return resArr.filter((item) => !!item).join(", ");
}

export function formatNumberWithZero(num) {
  return isNaN(num) ? String(num) : ("0" + String(num)).slice(-2);
}

/**
 * 把arrBusinessHours的hours转换到当前时区下
 * @param {Array} arrBusinessHours business_hours,形如[{type: 'monday_to_friday', hours: [{start_at: "07:27", end_at: "23:27"}]}]
 * @param {String} shopTimezone 店铺所在的时区
 */
export function formatBusinessHoursWithTZ(arrBusinessHours, shopTimezone) {
  if (!(arrBusinessHours && Array.isArray(arrBusinessHours) && shopTimezone)) {
    return arrBusinessHours;
  }

  function formatStartAtWithTZ(data, shopTimezone) {
    const [hour, minute] = data.split(":");
    const dayjsWithTZ = dayjs()
      .tz(shopTimezone)
      .hour(Number(hour))
      .minute(Number(minute))
      .second(0)
      .millisecond(0)
      .tz();

    return `${formatNumberWithZero(dayjsWithTZ.hour())}:${formatNumberWithZero(
      dayjsWithTZ.minute()
    )}`;
  }

  return arrBusinessHours.map((bhItem) => {
    const item = cloneDeep(bhItem);
    item.hours = item.hours.map((hourItem) => ({
      start_at: formatStartAtWithTZ(hourItem.start_at, shopTimezone),
      end_at: formatStartAtWithTZ(hourItem.end_at, shopTimezone),
    }));
    return item;
  });
}

export function formateTodayBusinessTime(businessTime) {
  const openTime = toDayBusinessHoursMap(businessTime);
  return openTime[new Date().getDay()]?.str || "";
}

export function formatDateWithTZ(timeStamp, pattern = "YYYY-MM-DD HH:mm") {
  // 修改时间戳判断逻辑
  return isNumber(timeStamp) && !isNaN(timeStamp) && timeStamp !== 0
    ? dayjs.tz(timeStamp).format(pattern)
    : "-";
}

export function formatDistance(distance) {
  return distance >= 1000
    ? (distance / 1000).toFixed(1) + "km"
    : distance.toFixed(1) + "m";
}

// 格式化优惠券的优惠金额
export function formatCouponReward(discountType, discountRule) {
  if (!discountType || !discountRule) {
    return "";
  }

  if (discountType === DiscountTypeEnum.FIXED) {
    const { useFormatPrice } = useCurrency();
    return useFormatPrice(discountRule.discount, discountRule.currency);
  } else if (discountType === DiscountTypeEnum.PERCENT) {
    return `${NP.times(discountRule.discount_percent, 100)}%`;
  } else {
    return "";
  }
}

// 格式化优惠券的日数
export function formatCouponValidDays(usingTimeRule) {
  if (!usingTimeRule) {
    return 0;
  }

  if (usingTimeRule.rule_type === UsingTimeRuleTypeEnum.FIXED) {
    if (usingTimeRule.start_time && usingTimeRule.end_time) {
      return Math.floor(
        (usingTimeRule.end_time - usingTimeRule.start_time) /
          (24 * 60 * 60 * 1000)
      );
    } else {
      return 0;
    }
  } else if (usingTimeRule.rule_type === UsingTimeRuleTypeEnum.AFTER_SEND) {
    return usingTimeRule.expired_interval || 0;
  } else {
    return 0;
  }
}
